import React from "react"
import Head from '../components/Head'
import Layout from '../components/Layout'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const ThankYou = () => {
    return (
        <Layout>
            <div className='branding-container'>
                <Head title="Thank you! | Saint Joseph's University and ICF Certified Executive & Team Coaching Training Program" description="The Executive & Team Coaching Certification Program is designed to equip today's leaderhip with the skills and tools to launch a successful executive coaching practice."/>
                <div className="mx-auto text-center pb-0 p-10 lg:w-3/5">
                    <h1 className="branding-h2 text-gray-700 mb-10">
                        Thank you!
                    </h1>
                    <p className="">
                        Thank you for your interest in Coach Training EDU! Here are some additional resources that you might find helpful.
                    </p>
                </div>
                <div className="bg-blue-lightlight mb-20 flex flex-col items-center">
                    <h3 className="branding-subheader text-center text-gray-700 p-6">
                        For additional information, please call
                    </h3>
                    <div className='flex items-center border-2 border-executive-primary py-3 pr-5 pl-5 mb-10' style={{width: '200px'}}>
                        <a href='tel:+1-503-360-6700' className='pr-5 text-executive-primary'>+1 (503) 360-6700</a>
                        <FontAwesomeIcon icon={faPhone} className='text-executive-primary'/>
                    </div>

                    <div className="space-y-10 md:space-y-0 lg:space-y-0">
                        <div style={{maxWidth: '250px'}} className="flex justify-center flex-col mx-5">
                            <StaticImage
                                className="rounded-full mb-5"
                                src='../images/Britt-Fulmer.png'
                                alt="Coaching Advisor Britt"
                            />
                            <a
                                href="https://calendly.com/britt-fulmer/30min"
                                className="w-auto button-text uppercase text-center text-executive-primary border-2 border-executive-primary p-2 px-4 hover:bg-executive-primary hover:text-white"
                            >Schedule a 20 minute call with Britt</a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ThankYou;